export const validateUppercase = (value, constraint) => {

    if(value.toUpperCase() === value) return { status: true }

    return { status: false, key: "validation.uppercase" }

}
export const validateClearingNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        console.log(rule)
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }

    return { status: false, key: `validation.clearingnumber.${message}` }    
}
export const validateAccountNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        console.log(rule)
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }
    return { status: false, key: `validation.accountnumber.${message}` }    
}