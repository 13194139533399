import axios from 'axios'
import { auth, envelopes, bisnode } from "@verified/libvf";
import moment from 'moment'
import {en, sv} from 'vuejs-datepicker/dist/locale'

export default {
  name: "app",
  data() {
    return {
			required :true,
			step: 1,
			lang: "sv_SE",
			loading: true,
			sent: false,
			methods:null,
			redirectUrl: "https://www.verified.eu/",
			isSubmit : false,
			sv : sv,
			disabledStartDates : {
				to: null
			},
			disabledEndDates : {
				to : null,
				from :null
			},
			showInvalidFeedback: {
				"datepicker-emp-start" : false,
				"datepicker-emp-end" : false,
			},
			ud: {
				company : {
					name : 'Replokalen.com AB',
					address : 'Adress: Smörhålevägen 3, 434 42 Kungsbacka',
					phone : 'Telefon: 0707-75 99 40',
					email : 'E-post: <a href="mainto:info@replokalen.com" >info@replokalen.com</a>',
					web : 'Web: <a href="https://replokalen.com/" target="_blank">www.replokalen.com</a>'
				},
				_isForwarded: false	,
				fileName: null,
				translations: null,
				lang: "sv_SE",
				contactPerson : {
					givenName :null,
					lastName : null,
					personNr  : null,
					email : null,
					mobile : null,
					postAddress : null,
					city : null,
					zip : null
				},
				contractDetails : {
					location : '',
					roomType : '',
					numberOfKeys : 1
				}
			}
		}
	},
	methods: {
		steps() {
			return [
				{ title: this.__('step1'),first:true,back : this.__('back') },
				{ title: this.__('step2') ,back : this.__('back')},
				{ title: this.__('step3'),back : this.__('back') }
			]
		},
		getUrlParameter(param, dummyPath) {
			var sPageURL = dummyPath || window.location.search.substring(1),
				sURLVariables = decodeURIComponent(sPageURL).split(/[&||?]/),
				res;
			for (var i = 0; i < sURLVariables.length; i += 1) {
				var paramName = sURLVariables[i],
					sParameterName = (paramName || '').split('=');
				if (sParameterName[0] === param) {
					res = sParameterName[1];
				}
			}
			return res;
		},
		dateFormat(dateval){
			return moment(dateval).format('YYYY-MM-DD')
		},
		nextStep() {
			if(this.step == 2){
				this.isSubmit = true
			}
			if(!this.validateForm(`form${this.step}`) ) {
				return
			}
			
			if(this.step >= 3)
				return this.submit()

			this.step++

		},
		backStep() {
			this.step--
		},
		previousStep(n) {
			this.step = n
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		async fetchBisnode(){
				try{
					this.busy = true
					this.isBisnodeFetched = false		
					// if(type=='tentant'){
						let person = await bisnode.sweden.getPersonInfo(this.ud.contactPerson.personNr.replace(/[\-\s]/g,""));
						let user = person.consumer
						console.log(user)
						this.ud.contactPerson.givenName = user.firstName
						this.ud.contactPerson.familyName = user.surname
						this.ud.contactPerson.zip = user.postCode.replace(' ','')
						this.ud.contactPerson.postAddress = user.postalAdressLine1
						this.ud.contactPerson.city = user.town
					// }
				}catch(ex){
					// console.log(ex)
				}
				// console.log(this.ud.firstName)
				this.isBisnodeFetched = true
				this.busy = false
		},
		async addRecipients(envelope){
			let promises = []
			let index = 1
			//Add tenant
			promises.push(
				envelope.addRecipient({
				"givenName": this.ud.contactPerson.givenName,
				"familyName": this.ud.contactPerson.familyName,
				"email": this.ud.contactPerson.email,
				"language": "sv_SE",
				"signingMethod": "bankid-se",
				"order": 1,
				"role": {
					"action": "sign",
					"label": "Public template signer",
					"name": "signer"
				}
			}))
			index++;
				promises.push(
					envelope.addRecipient({
					"givenName": this.__('defaultFirstName'),
					"familyName": this.__('defaultLastName'),
					"email": this.__('defaultEmail'),
					"language": "sv_SE",
					"signingMethod": "email",
					"order": 10,
					"role": {
						"action": "sign",
						"label": "Public template signer",
						"name": "signer"
					}
				}))

			return Promise.all(promises)
		},
		async submit() {
			
			if(!this.validateForm('form1') && !this.validateForm('form2') && !this.validateForm('form3') ){
				return
			}
			if(this.ud.rentStart){
				this.ud.contractDetails.startDate = this.dateFormat(this.ud.rentStart)
			}
			// if(this.ud.rentEnd){
			// 	this.ud.contractDetails.endDate = this.dateFormat(this.ud.rentEnd)
			// }
			if(this.ud.contractDetails.roomType==this.__('typeOp3')){
				this.ud.contractDetails.cancellationPeriod = 1
				this.ud.contractDetails.initialPeriod = 6
			}else if(this.ud.contractDetails.roomType==this.__('typeOp1')||this.ud.contractDetails.roomType==this.__('typeOp2')){
				this.ud.contractDetails.cancellationPeriod = 3
				this.ud.contractDetails.initialPeriod = 9
			}
			this.ud.contactPerson.postalNumberAndPlace = this.ud.contactPerson.zip+' '+this.ud.contactPerson.city
			this.loading = true
			let envelope = null
			let self = this
			// console.log(JSON.stringify(self.ud))
			self.fileName = this.ud.fileName+this.ud.contractDetails.bandName+'-'+this.ud.contractDetails.startDate;
			await envelopes.create('replokalen').then(function (res) {
				envelope = res;
			}).then(function () {
				return envelope.getBearerToken('/flows/simple-public-flow');
			}).then(function (token) {
				return auth.setToken(token);
			}).then(function (env) {
				return envelope.reflect();
			}).then(function(){
				envelope.firstTemplate().setUserData(self.ud)
			}).then(function () {
				return self.addRecipients(envelope)
			}).then(function () {
				return envelope.firstDocument().put({ name: self.fileName+'.pdf' });
			}).then(function () {
				return envelope.publish();
			}).then(function () {
				self.sent = true
				envelope.getSignToken("/flows/simple-public-flow")
					.then(function (token) {
						self.sent = true
						// window.location.href = self.redirectUrl;
						var signUrl = "https://app.verified.eu/#/sign"+ envelope.data.uid + "?access_token=" + token + "&lang=" + self.ud.lang + "&redirect_to=" + encodeURIComponent(self.redirectUrl);
						// localStorage.setItem(envelope.data.id, $scope.signUrl);
						console.log("Got sign URL:", signUrl);
						window.location.href = signUrl;
					});
			}).catch(function (err) {
				console.log("ERROR", err);
				self.loading = false;
				throw err;
			});
			

		}
	},
	watch: {
		"ud.contactPerson.personNr": function (newVal, oldVal) {
				 if(!this.ud.contactPerson.personNr) return;
				var ssn = this.ud.contactPerson.personNr.replace(/[\-\s]/g,"");
				if(ssn.length == 12)
				{
					this.fetchBisnode();
				}
		},
		"ud.rentStart": function (newVal, oldVal) {
			if(!this.ud.rentStart) return;
			var rentStart = this.ud.rentStart
			// this.ud.rentEnd = this.getSixthMonthDate;
			// this.disabledEndDates = {
			// 	to: new Date(this.ud.rentStart),
			// 	from : new Date(this.ud.rentEnd)
			// }
			// this.ud.rentEnd = rentStart;
		},
		"ud.contractDetails.location": function (newVal, oldVal) {
			if(!this.ud.contractDetails.location) return;
			console.log(this.__('localRentInfo3'))
			if(this.ud.contractDetails.location == this.__('placeOp1')){
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp1Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}else if(this.ud.contractDetails.location == this.__('placeOp2')){
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp2Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}else if(this.ud.contractDetails.location == this.__('placeOp3')){
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('place3LocalRentInfo3').replace('%place%',this.__('placeOp3Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}else{
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp4Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}
		},
		"ud.contractDetails.numberOfKeys": function (newVal, oldVal) {
			if(!this.ud.contractDetails.numberOfKeys) return;
			console.log(this.__('localRentInfo3'))
			if(this.ud.contractDetails.location == this.__('placeOp1')){
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp1Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}else if(this.ud.contractDetails.location == this.__('placeOp2')){
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp2Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}else if(this.ud.contractDetails.location == this.__('placeOp3')){
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('place3LocalRentInfo3').replace('%place%',this.__('placeOp3Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}else{
				var total = parseInt(this.ud.contractDetails.numberOfKeys)*300
				this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp4Val'))
				this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',total)
			}
		}
    },
	computed: {
		getSixthMonthDate(){
			let endDate = moment(this.ud.rentStart)
			return endDate.add(9, 'months').subtract(1, 'days').toDate()
		},
		items(){
			
		}

	},
	async created() {
		// Set language to use
		this.setIso(this.lang)

		const [translations] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/replokalen/translations')
		])
		this.ud.source    = this.getUrlParameter('source')||'replokalen';	

		Object.keys(translations.data).forEach(element => {
			translations.data[element][this.lang] = translations.data[element][this.ud.source]
		});
		this.mergeLocale(translations.data)
		this.ud.translations = translations.data
		
		document.title   = this.__('browserTitle');
		this.redirectUrl = this.__('redirectUrl');
		this.ud.fileName = this.__('fileName');

		let cover = document.getElementById("cover"); 
		cover.style.backgroundImage = `url(${this.__('background')})` 
		// this.disabledStartDates.to = moment().set('hour', 0).set('minute',0).set('second',0).toDate()
		this.ud.contractDetails.roomType = this.__('typeOp1')
		this.ud.contractDetails.location = this.__('placeOp1')
		this.ud.conditionsHead = this.__('conditionsHead').replace('%type%',this.ud.contractDetails.roomType)
		this.ud.localRentInfo3 = this.__('localRentInfo3').replace('%place%',this.__('placeOp1Val'))
		this.ud.localRentInfo3 = this.ud.localRentInfo3.replace('%total%',300)
		let authOpts = {token:this.__('token'), namespace:this.__('namespace')}
		auth.authenticate(authOpts)
		// this.setMonthRange();
		this.loading = false;
	}
}