import Vue from 'vue'
import App from './page/App.vue'
import veform from '@verified/veform'
import { validateUppercase} from './validations'
import { validateClearingNumber} from './validations'
import { validateAccountNumber} from './validations'
import Stepper from './components/stepper'
import Checkbox from './components/checkbox'
import Datepicker from 'vuejs-datepicker';
import VueMonthlyPicker from 'vue-monthly-picker'

Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('uppercase', validateUppercase)
veform.addCustomValidation('clearing-number', validateClearingNumber)
veform.addCustomValidation('account-number', validateAccountNumber)

Vue.use(veform)
Vue.use(Stepper)
Vue.use(Checkbox)
Vue.component('Datepicker',Datepicker)
Vue.component('VueMonthlyPicker',VueMonthlyPicker)
new Vue({
    render: h => h(App),
}).$mount('#app')